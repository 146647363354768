<template>
  <ValidationObserver :ref="`rewardForm-${id}`" v-slot="{ handleSubmit }">
    <form @submit.prevent="handleSubmit(onAddReward)">
      <b-row>
        <b-col cols="3">
          <b-form-group :label="$t('rewards.name')" :label-for="`title${id}`">
            <b-form-input
              :id="`title${id}`"
              v-model="rewardForm.title"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group
            :label="$t('rewards.random_percentage')"
            :label-for="`rate${id}`"
          >
            <b-form-input
              :id="`rate${id}`"
              v-model="rewardForm.chance"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            :label="$t('rewards.reward_amount')"
            :label-for="`credit${id}`"
          >
            <b-form-input
              :id="`credit${id}`"
              v-model="rewardForm.creditEarns"
              type="number"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col cols="2" class="p-0">
          
          <b-form-group v-if="id" :label="`${$t('buttons.edit')}/${$t('buttons.remove')}`">
             <b-overlay
                :show="isUpdatingReward || isDeletingReward"
                rounded
                spinner-small
                class="d-inline-block"
              >
                <b-button variant="warning" @click="onUpdateReward(id)">
                  <i class="uil uil-edit"></i>
                </b-button>
                <b-button variant="danger" disabled @click="onRemoveReward(id)">
                  <i class="uil uil-trash"></i>
                </b-button>
              </b-overlay>
          </b-form-group>
           <b-form-group v-else :label="$t('buttons.add')">
            <b-overlay
              :show="isAddingReward"
              rounded
              spinner-small
              class="d-inline-block"
            >
              <b-button variant="primary" type="submit">
                <i class="uil uil-plus"></i>
              </b-button>
            </b-overlay>
          
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col> </b-col>
      </b-row>
    </form>
  </ValidationObserver>
</template>

<script>
import { mapActions,  mapState } from 'vuex'

export default {
  name:'RewardForm',
  props: {
    id: {
      type: String,
      default: '',
    },
    agentId: {
      type: String,
      required: true,
    },
    boxId: {
      type: String,
      required: true,
    },
    value: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      rewardForm: {
        title: '',
        creditEarns: 0,
        chance: 0,
      },
    }
  },
  computed: {
    ...mapState({
      isAddingReward: (state) => state.user.isAddingRewardBox,
      isUpdatingReward: (state) => state.user.isUpdatingRewardBox,
      isDeletingReward: (state) => state.user.isDeletingRewardBox,
    }),
  },
  watch: {
    value(val) {
      if (val) {
        this.setData()
      }
    },
  },
  created() {
    this.setData()
  },
  methods: {
    ...mapActions([
      'addRewardBox',
      'updateRewardBox',
      'deleteRewardBox',
    ]),
    setData() {
      if (this.value) {
        const data = JSON.parse(JSON.stringify(this.value))
        this.rewardForm = { ...data }
      } else {
        this.rewardForm = { 
          title: '',
        creditEarns: 0,
        chance: 0, }
      }
    },
    async onAddReward() {
      this.rewardForm.chance = +this.rewardForm.chance
      await this.addRewardBox({
        agentId: this.agentId,
        boxId: this.boxId,
        data: this.rewardForm,
      })
      this.rewardForm = {
        title: '',
        creditEarns: 0,
        chance: 0,
      }
    },
    onUpdateReward(rewardId) {
       this.addRewardBox({
        agentId: this.agentId,
        boxId: this.boxId,
        rewardId ,
        data: this.rewardForm,
      })
    },
    // ! TODO no delete API
    onRemoveReward(rewardId) {
      this.$bvModal
        .msgBoxConfirm(`${this.$t('messages.confirm')}`, {
          okTitle: `${this.$t('buttons.confirm')}`,
          cancelTitle: `${this.$t('buttons.cancel')}`,
        })
        .then((confirm) => {
          if (confirm) {
            this.deleteRewardBox({
              agentId: this.agentId,
              boxId: this.boxId,
              rewardId,
            })
          }
        })
    },
  },
}
</script>
